// This file includes polyfills needed by Angular 2 and is loaded before
// the app. You can add your own extra polyfills to this file.
import 'core-js/es/symbol';
import 'core-js/es/object';
import 'core-js/es/function';
import 'core-js/es/parse-int';
import 'core-js/es/parse-float';
import 'core-js/es/number';
import 'core-js/es/math';
import 'core-js/es/string';
import 'core-js/es/date';
import 'core-js/es/array';
import 'core-js/es/regexp';
import 'core-js/es/map';
import 'core-js/es/set';
import 'core-js/es/reflect';
import 'zone.js/dist/zone';
import { IDialer } from '@app/Common/interfaces/dialer';
import { IRconManager } from '@app/Common/interfaces/rcon';
import { IWebAppManager } from '@app/Common/interfaces/webAppManager';

(window as any).global = window;
global.Buffer = global.Buffer || require('buffer').Buffer;

declare global {
	interface Window {
		dialer?: IDialer;
		n2p: {};
		gtag: Function;
		webAppManager: IWebAppManager;
		rconManager?: IRconManager;
		_LTracker: any[];
		n2pLogger: any;
		FETCHED_ENV_DATA: { [key: string]: any };
	}
}

window.n2p = {};
